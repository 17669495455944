import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  GetOrganizationInfoResponse,
  InsuranceProvision,
  OrganizationType,
  UpdateOrganizationInfoAddressInfo,
  UpdateOrganizationInfoErrorCode,
  UpdateOrganizationInfoOrganizationInfoClinicInfo,
  UpdateOrganizationInfoOrganizationInfoSchoolInfo,
} from "@platform-app/app/core/api/models";
import {
  OrganizationService,
  SchoolService,
} from "@platform-app/app/core/api/services";
import { AuthState } from "@platform-app/app/core/auth/auth.state";
import {
  GetCurrentOrganizationInfo,
  UpdateOrganization,
  OrganizationInfoUpdated,
  AddAccreditation,
  AddCredential,
  UpdateAccreditation,
  UpdateCredential,
  DeleteAccreditation,
  DeleteCredential,
} from "@platform-app/app/main/organization-settings-page/profile-settings-page/organization-info.actions";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";
import { catchError, finalize, Observable, of, switchMap, tap } from "rxjs";

export interface OrganizationInfoStateModel {
  saveLoading: boolean;
  dataLoading: boolean;
  organizationInfo: GetOrganizationInfoResponse | null;
  errors: string[];
  updateAccreditationLoading: boolean;
}

export interface UpdateOrganizationInfoRequest {
  name?: string | null;
  organizationType?: OrganizationType;
  clinic?: UpdateOrganizationInfoOrganizationInfoClinicInfo;
  school?: UpdateOrganizationInfoOrganizationInfoSchoolInfo;
  websiteUrl?: string | null;
  address?: UpdateOrganizationInfoAddressInfo;
  description?: string | null;
  insuranceProvision?: InsuranceProvision;
  phoneNumber?: string | null;
  phoneExtension?: string | null;
  email?: string | null;
}

const DEFAULT_ORGANIZATION_INFO_STATE: OrganizationInfoStateModel = {
  organizationInfo: null,
  saveLoading: false,
  dataLoading: false,
  errors: [],
  updateAccreditationLoading: false,
};

@State<OrganizationInfoStateModel>({
  name: "OrganizationInfo",
  defaults: DEFAULT_ORGANIZATION_INFO_STATE,
})
@Injectable()
export class OrganizationInfoState extends StateToDefaultResetter<OrganizationInfoStateModel> {
  constructor(
    private organizationService: OrganizationService,
    private schoolService: SchoolService,
    private store: Store,
  ) {
    super(DEFAULT_ORGANIZATION_INFO_STATE);
  }

  @Selector()
  static saveLoading(state: OrganizationInfoStateModel) {
    return state.saveLoading;
  }

  @Selector()
  static dataLoading(state: OrganizationInfoStateModel) {
    return state.dataLoading;
  }

  @Selector()
  static organizationInfo(state: OrganizationInfoStateModel) {
    return state.organizationInfo;
  }

  @Selector()
  static errors(state: OrganizationInfoStateModel) {
    return state.errors;
  }

  @Selector()
  static updateAccreditationLoading(state: OrganizationInfoStateModel) {
    return state.updateAccreditationLoading;
  }

  @Action(GetCurrentOrganizationInfo)
  getCurrentOrganizationInfo(ctx: StateContext<OrganizationInfoStateModel>) {
    ctx.patchState({
      dataLoading: true,
      errors: [],
    });

    const organizationId = this.getOrganizationId();

    return this.getOrganizationInfoRequest(organizationId, ctx).pipe(
      finalize(() => {
        ctx.patchState({
          dataLoading: false,
        });
      }),
    );
  }

  @Action(UpdateOrganization)
  updateOrganization(
    ctx: StateContext<OrganizationInfoStateModel>,
    action: UpdateOrganization,
  ) {
    ctx.patchState({
      saveLoading: true,
      errors: [],
    });

    const organizationId = this.getOrganizationId();

    return this.organizationService
      .organizationInfoPut({
        body: {
          Json: {
            ...action.payload.organizationInfo,
            id: organizationId,
            name: action.payload.organizationInfo.name!,
          },
          ...(action.payload.pictureFile && {
            File: action.payload.pictureFile,
          }),
        },
      })
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 400) {
            const errors = JSON.parse(error.error).errors ?? error.error;

            ctx.patchState({
              errors:
                errors?.map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (e: any) => {
                    return e.code
                      ? (e.code as UpdateOrganizationInfoErrorCode)
                      : (e as string);
                  },
                ) || [],
            });
          }

          return of(error);
        }),
        switchMap(() => {
          const stateSnapshot = ctx.getState();
          return stateSnapshot.errors.length
            ? of(null)
            : this.getOrganizationInfoRequest(organizationId, ctx);
        }),
        tap((response) => {
          if (response)
            ctx.dispatch(
              new OrganizationInfoUpdated({
                organization: response,
              }),
            );
        }),
        finalize(() => {
          ctx.patchState({
            saveLoading: false,
          });
        }),
      );
  }

  @Action(AddAccreditation)
  addAccreditation(
    ctx: StateContext<OrganizationInfoStateModel>,
    action: AddAccreditation,
  ) {
    const organizationId = this.getOrganizationId();

    ctx.patchState({
      updateAccreditationLoading: true,
    });

    return this.schoolService
      .addAccreditation({
        schoolId: organizationId,
        body: action.payload.accreditation,
      })
      .pipe(
        switchMap(() => this.getOrganizationInfoRequest(organizationId, ctx)),
        finalize(() => {
          ctx.patchState({
            updateAccreditationLoading: false,
          });
        }),
      );
  }

  @Action(AddCredential)
  addCredential(
    ctx: StateContext<OrganizationInfoStateModel>,
    action: AddCredential,
  ) {
    const organizationId = this.getOrganizationId();

    ctx.patchState({
      updateAccreditationLoading: true,
    });

    return this.schoolService
      .addCredential({
        schoolId: organizationId,
        body: action.payload.credential,
      })
      .pipe(
        switchMap(() => this.getOrganizationInfoRequest(organizationId, ctx)),
        finalize(() => {
          ctx.patchState({
            updateAccreditationLoading: false,
          });
        }),
      );
  }

  @Action(UpdateAccreditation)
  updateAccreditation(
    ctx: StateContext<OrganizationInfoStateModel>,
    action: UpdateAccreditation,
  ) {
    const organizationId = this.getOrganizationId();

    ctx.patchState({
      updateAccreditationLoading: true,
    });

    return this.schoolService
      .updateAccreditation({
        schoolId: organizationId,
        accreditationId: action.payload.accreditationId,
        body: action.payload.accreditation,
      })
      .pipe(
        switchMap(() => this.getOrganizationInfoRequest(organizationId, ctx)),
        finalize(() => {
          ctx.patchState({
            updateAccreditationLoading: false,
          });
        }),
      );
  }

  @Action(UpdateCredential)
  updateCredential(
    ctx: StateContext<OrganizationInfoStateModel>,
    action: UpdateCredential,
  ) {
    const organizationId = this.getOrganizationId();

    ctx.patchState({
      updateAccreditationLoading: true,
    });

    return this.schoolService
      .updateCredential({
        schoolId: organizationId,
        credentialId: action.payload.credentialId,
        body: action.payload.credential,
      })
      .pipe(
        switchMap(() => this.getOrganizationInfoRequest(organizationId, ctx)),
        finalize(() => {
          ctx.patchState({
            updateAccreditationLoading: false,
          });
        }),
      );
  }

  @Action(DeleteAccreditation)
  deleteAccreditation(
    ctx: StateContext<OrganizationInfoStateModel>,
    action: DeleteAccreditation,
  ) {
    const organizationId = this.getOrganizationId();

    return this.schoolService
      .deleteAccreditation({
        schoolId: organizationId,
        accreditationId: action.payload.id,
      })
      .pipe(
        switchMap(() => this.getOrganizationInfoRequest(organizationId, ctx)),
      );
  }

  @Action(DeleteCredential)
  deleteCredential(
    ctx: StateContext<OrganizationInfoStateModel>,
    action: DeleteCredential,
  ) {
    const organizationId = this.getOrganizationId();

    return this.schoolService
      .deleteCredential({
        schoolId: organizationId,
        credentialId: action.payload.id,
      })
      .pipe(
        switchMap(() => this.getOrganizationInfoRequest(organizationId, ctx)),
      );
  }

  private getOrganizationId(): string {
    const organizationId = this.store.selectSnapshot(AuthState.user)
      ?.organization?.id;
    if (!organizationId) {
      throw new Error("Current User does not have Organization id");
    }
    return organizationId;
  }

  private getOrganizationInfoRequest(
    organizationId: string,
    ctx: StateContext<OrganizationInfoStateModel>,
  ): Observable<GetOrganizationInfoResponse> {
    return this.organizationService
      .organizationOrganizationIdGet({
        organizationId: organizationId,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            organizationInfo: response,
          });
        }),
        catchError((error) => {
          ctx.patchState({
            errors: [error.error],
          });
          return of(error);
        }),
      );
  }
}
